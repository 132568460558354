if (setCookieDomain == undefined) {
    function setCookieDomain(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        var domain =
            "." +
            location.hostname.substring(
                location.hostname.indexOf("dafiti"),
                location.hostname.length
            );
        document.cookie =
            name + "=" + (value || "") + expires + "; path=/; domain=" + domain;
    }
}
if (callGaEvent == undefined) {
    function callGaEvent(status) {
        if (dataLayer == undefined) return;

        if (window.location.href.indexOf("account/login") >= 1) {
            dataLayer.push({
                event: "ab_test_event_ga4",
                ab_test_name: "new_login_front",
                ab_test_variant: status,
            });
        }
    }
}

configCatReady.then(function () {
    configCatClient
        .getValueAsync("hashloginfront", false, userObject)
        .then(function (value) {
            if (value) {
                setCookieDomain("new_login_front", "1", 1);
                callGaEvent("ligado");
            } else {
                setCookieDomain("new_login_front", "", -1);
                callGaEvent("desligado");
            }
        });

    configCatClient
        .getValueAsync("xconfig", false, userObject)
        .then(function (value) {
            if (value) {
                setCookieDomain("x-config", true, 1);
            } else {
                setCookieDomain("x-config", false, -1);
            }
        });

    configCatClient
        .getValueAsync("installmentsitem", false, userObject)
        .then(function (value) {
            if (value) {
                setCookieDomain("carrinho_alterado_PCJ", true, 1);
            } else {
                setCookieDomain("carrinho_alterado_PCJ", false, -1);
            }
        });
})


